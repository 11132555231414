import { graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import React from 'react';
import { MediaMatcher, MediaServerRender } from 'react-media-match';
import BlueArrow from '../src/components/BlueArrow/BlueArrow';
import Bragi from '../src/components/Bragi/bragi';
import { Freya } from '../src/components/Freya/Freya';
import { Fulla } from '../src/components/Fulla/Fulla';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Hod from '../src/components/Hod/Hod';
import Layout from '../src/components/Layout/Layout';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import { Odin } from '../src/components/Odinv2/Odin';
import TitleH3 from '../src/components/Titles/TitleH3/TitleH3';
import buildFunnelUrl from '../src/helpers/buildFunnelUrl';
import SEO from '../src/helpers/seo';
import tt from '../src/helpers/translation';
import './ficha_promocion.scss';

class PromotionsDetails extends React.Component {
  state = {};

  render() {
    let fullaData;
    let fullaDataMobile;

    const heimdallData = {
      name: this.props.data.allPromocionesTitleBlock.edges[0].node.title,
      subtitle: this.props.data.allPromocionesTitleBlock.edges[0].node.subTitle,
      image: {
        url: this.props.data.allPromocionesBlockImageInfoGeneral.edges[0].node.localImage
          .childImageSharp.fluid,
        alt: this.props.data.allPromocionesBlockImageInfoGeneral.edges[0].node.alt || 'image',
      },
      imageMobile: {
        alt: this.props.data.allPromocionesMobileTitleBlock.edges[0].node.alt,
        url: this.props.data.allPromocionesMobileTitleBlock.edges[0].node.localImage.childImageSharp
          .fluid,
      },
    };

    const odinData = {
      image:
        this.props.data.allPromocionesEventBlock.edges[0].node.localImage.childImageSharp.fluid,
      alt: this.props.data.allPromocionesEventBlock.edges[0].node.alt,
      title: this.props.data.allPromocionesEventBlock.edges[0].node.event_name,
      description: this.props.data.allPromocionesEventBlock.edges[0].node.description,
      rssImg: true,
      buttons: {
        cta1: this.props.data.allPromocionesEventBlock.edges[0].node.call_to_action_path,
        ctaText1: this.props.data.allPromocionesEventBlock.edges[0].node.call_to_action_copy,
        size: 'alone',
        color1: 'orange',
      },
    };

    if (this.props.pageContext.group_name === 'noche-italiana') {
      fullaData = {
        titleText: this.props.data.allPromocionesContentMultiColumnBlockLeft
          ? this.props.data.allPromocionesContentMultiColumnBlockLeft.edges[0].node.title
          : null,
        titleType: 'H2',
        fullaBody: this.props.data.allPromocionesContentMultiColumnBlockLeft
          ? this.props.data.allPromocionesContentMultiColumnBlockLeft.edges[0].node.body_left
          : null,
        buttons: {
          cta1: tt('/entradas/ferrari-land/', this.props.pageContext.locale),
          ctaText1: tt('COMPRAR ENTRADAS', this.props.pageContext.locale),
          size: 'alone',
          color1: 'orange',
        },
        secondaryTitleText: this.props.data.allPromosFullaBlock.edges[0].node.title,
        secondaryfullaBody: this.props.data.allPromosFullaBlock.edges[0].node.body,
      };
    } else if (
      this.props.pageContext.group_name === 'entrada-hotel' ||
      this.props.pageContext.group_name === 'oferta-hotel-entrada' ||
      this.props.pageContext.group_name === 'oferta-hotel-entrada' ||
      this.props.pageContext.group_name === 'vacaciones-primavera'
    ) {
      fullaData = {
        titleText: this.props.data.allPromocionesContentMultiColumnBlockLeft
          ? this.props.data.allPromocionesContentMultiColumnBlockLeft.edges[0].node.title
          : null,
        fullaBody: this.props.data.allPromocionesContentMultiColumnBlockLeft
          ? this.props.data.allPromocionesContentMultiColumnBlockLeft.edges[0].node.body_left
          : null,
        buttons: {
          cta1: buildFunnelUrl(this.props.pageContext.locale, 'hotels'),
          ctaText1: tt('RESERVAR AHORA', this.props.pageContext.locale),
          size: 'alone',
          color1: 'orange',
        },
        secondaryTitleText: this.props.data.allPromosFullaBlock.edges[0].node.title,
        secondaryfullaBody: this.props.data.allPromosFullaBlock.edges[0].node.body,
      };
    } else if (
      this.props.pageContext.group_name === 'vacaciones-verano' ||
      this.props.pageContext.group_name === 'vacaciones' ||
      this.props.pageContext.group_name === 'hotel'
    ) {
      fullaData = {
        titleText: this.props.data.allPromocionesContentMultiColumnBlockLeft
          ? this.props.data.allPromocionesContentMultiColumnBlockLeft.edges[0].node.title
          : null,
        fullaBody: this.props.data.allPromocionesContentMultiColumnBlockLeft
          ? this.props.data.allPromocionesContentMultiColumnBlockLeft.edges[0].node.body_left
          : null,
        secondaryTitleText: this.props.data.allPromosFullaBlock.edges[0].node.title,
        secondaryfullaBody: this.props.data.allPromosFullaBlock.edges[0].node.body,
      };
    } else {
      fullaData = {
        titleText: this.props.data.allPromocionesContentMultiColumnBlockLeft
          ? this.props.data.allPromocionesContentMultiColumnBlockLeft.edges[0].node.title
          : null,
        fullaBody: this.props.data.allPromocionesContentMultiColumnBlockLeft
          ? this.props.data.allPromocionesContentMultiColumnBlockLeft.edges[0].node.body_left
          : null,
        buttons: {
          cta1:
            this.props.pageContext.group_name === 'la-isla-maldita'
              ? buildFunnelUrl(this.props.pageContext.locale, 'hotels')
              : tt('/entradas', this.props.pageContext.locale),
          ctaText1:
            this.props.pageContext.group_name === 'la-isla-maldita'
              ? tt('RESERVAR AHORA', this.props.pageContext.locale)
              : tt('COMPRAR ENTRADAS', this.props.pageContext.locale),
          size: 'alone',
          color1: 'orange',
        },
        secondaryTitleText: this.props.data.allPromosFullaBlock.edges[0].node.title,
        secondaryfullaBody: this.props.data.allPromosFullaBlock.edges[0].node.body,
      };
    }

    if (
      this.props.pageContext.group_name === 'entrada-hotel' ||
      this.props.pageContext.group_name === 'oferta-hotel-entrada' ||
      this.props.pageContext.group_name === 'oferta-hotel-entrada' ||
      this.props.pageContext.group_name === 'vacaciones-primavera'
    ) {
      fullaDataMobile = {
        titleText: this.props.data.allPromocionesContentMultiColumnBlockLeft
          ? this.props.data.allPromocionesContentMultiColumnBlockLeft.edges[0].node.title
          : null,
        fullaBody: this.props.data.allPromocionesContentMultiColumnBlockLeft
          ? this.props.data.allPromocionesContentMultiColumnBlockLeft.edges[0].node.body_left
          : null,
        buttons: {
          cta1: buildFunnelUrl(this.props.pageContext.locale, 'hotels'),
          ctaText1: tt('RESERVAR AHORA', this.props.pageContext.locale),
          size: 'alone',
          color1: 'orange',
        },
        secondaryTitleText: this.props.data.allPromosFullaBlock.edges[0].node.title,
        secondaryfullaBody: this.props.data.allPromosFullaBlock.edges[0].node.body,
      };
    } else if (
      this.props.pageContext.group_name === 'vacaciones-verano' ||
      this.props.pageContext.group_name === 'vacaciones'
    ) {
      fullaDataMobile = {
        titleText: this.props.data.allPromocionesContentMultiColumnBlockLeft
          ? this.props.data.allPromocionesContentMultiColumnBlockLeft.edges[0].node.title
          : null,
        fullaBody: this.props.data.allPromocionesContentMultiColumnBlockLeft
          ? this.props.data.allPromocionesContentMultiColumnBlockLeft.edges[0].node.body_left
          : null,

        secondaryTitleText: this.props.data.allPromosFullaBlock.edges[0].node.title,
        secondaryfullaBody: this.props.data.allPromosFullaBlock.edges[0].node.body,
      };
    } else {
      fullaDataMobile = {
        titleText: this.props.data.allPromocionesContentMultiColumnBlockLeft
          ? this.props.data.allPromocionesContentMultiColumnBlockLeft.edges[0].node.title
          : null,
        titleType: this.props.pageContext.pageName === 'noche-italiana' && 'H2',
        fullaBody: this.props.data.allPromocionesContentMultiColumnBlockLeft
          ? this.props.data.allPromocionesContentMultiColumnBlockLeft.edges[0].node.body_left
          : null,
        buttons: {
          cta1:
            this.props.pageContext.group_name === 'la-isla-maldita'
              ? buildFunnelUrl(this.props.pageContext.locale, 'hotels')
              : tt('/entradas', this.props.pageContext.locale),
          ctaText1:
            this.props.pageContext.group_name === 'la-isla-maldita'
              ? tt('RESERVAR AHORA', this.props.pageContext.locale)
              : tt('COMPRAR ENTRADAS', this.props.pageContext.locale),
          size: 'alone',
          color1: 'orange',
        },
        secondaryTitleText: this.props.data.allPromosFullaBlock.edges[0].node.title,
        secondaryfullaBody: this.props.data.allPromosFullaBlock.edges[0].node.body,
      };
    }

    const freyaData = {
      title: tt('PROMOCIONES RELACIONADAS', this.props.pageContext.locale),
      cards: this.props.data.allPromocionesPromosSubBlock.edges.map((item) => ({
        image: item.node.localImage.childImageSharp.fluid,
        altImage: item.node.alt,
        title: item.node.title,
        imgsize: 'promoted',
        buttons: {
          cta: item.node.buttons.cta,
          size: item.node.buttons.size,
          color: item.node.buttons.color,
          ctaText: item.node.buttons.ctaText,
        },
      })),
    };

    const isMobileOdin = (
      <>
        <Odin data={odinData} locale={this.props.pageContext.locale} />
        {this.props.pageContext.group_name === 'portaventura-park-entradas-2-dias' ? (
          <div className="banner-image">
            <Img
              alt="image"
              fluid={this.props.data.bannerDesktop.edges[0].node.localImage.childImageSharp.fluid}
            />
          </div>
        ) : null}
        {this.props.pageContext.group_name === 'portaventura-park-entradas-2-dias' ? (
          <div className="banner-image-mobile">
            <Img
              alt="image"
              fluid={this.props.data.bannerMobile.edges[0].node.localImage.childImageSharp.fluid}
            />
          </div>
        ) : null}
        <Fulla context={this.props.pageContext.group_name} data={fullaDataMobile} />
      </>
    );
    const isDesktopOdin = (
      <>
        <Odin data={odinData} locale={this.props.pageContext.locale} />
        {this.props.pageContext.group_name === 'portaventura-park-entradas-2-dias' ? (
          <div className="banner-image">
            <Img
              alt="image"
              fluid={this.props.data.bannerDesktop.edges[0].node.localImage.childImageSharp.fluid}
            />
          </div>
        ) : null}
        {this.props.pageContext.group_name === 'portaventura-park-entradas-2-dias' ? (
          <div className="banner-image-mobile">
            <Img
              alt="image"
              fluid={this.props.data.bannerMobile.edges[0].node.localImage.childImageSharp.fluid}
            />
          </div>
        ) : null}
        <Fulla context={this.props.pageContext.group_name} data={fullaData} />
      </>
    );

    const titleData = {
      title: tt('OTRAS PROMOCIONES', this.props.pageContext.locale),
    };

    return (
      <Layout
        layoutFromOld
        locale={this.props.pageContext.locale}
        pageName={this.props.pageContext.pageName}
        options={this.props.pageContext.options}
      >
        <SEO
          title={this.props.data.allPromocionesSeoData.edges[0].node._0.title}
          description={this.props.data.allPromocionesSeoData.edges[0].node._1.description}
          imageRich={this.props.data.allFichaPromosRichData.edges[0].node.rich_markup.imgRich}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <div className="general-index">
            <div className="promotions-details-content">
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                options={this.props.pageContext.options}
                title={this.props.data.allFichaPromosBreadCrumbBlock.edges[0].node.name}
              />
              <Hod
                data={this.props.data.allFichaPromosRichData.edges[0].node.rich_markup}
                url={this.props.pageContext.url}
                img={this.props.data.allFichaPromosRichData.edges[0].node.imgRich}
              />
              <BlueArrow />

              <MediaServerRender predicted="mobile" hydrated>
                <MediaMatcher mobile={isMobileOdin} tablet={isMobileOdin} desktop={isDesktopOdin} />
              </MediaServerRender>
              {this.props.data.fichaPromosVideo && (
                <div className="video-container">
                  <Bragi url={this.props.data.fichaPromosVideo.video} />
                </div>
              )}
              <div className="freya-prom-container">
                <TitleH3 data={titleData} />
                <Freya data={freyaData} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

PromotionsDetails.propTypes = {};

export default PromotionsDetails;

export const pageQuery = ({ data, pathContext: { locale } }) => graphql`
  query allFichaPromocionQuery($group_name: String!, $locale: String!) {
    allPromocionesTitleBlock(filter: { tag: { eq: $group_name }, lang: { eq: $locale } }) {
      edges {
        node {
          title
          subTitle
          description
        }
      }
    }
    allPromocionesSeoData(filter: { tag: { eq: $group_name }, lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allPromocionesBlockImageInfoGeneral(
      filter: { tag: { eq: $group_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          alt
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allPromocionesMobileTitleBlock(filter: { tag: { eq: $group_name }, lang: { eq: $locale } }) {
      edges {
        node {
          alt
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allPromocionesEventBlock(filter: { tag: { eq: $group_name }, lang: { eq: $locale } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
          description
          call_to_action_copy
          call_to_action_path
          event_name
        }
      }
    }
    allPromocionesContentMultiColumnBlockLeft(
      filter: { tag: { eq: $group_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          title
          body_left
        }
      }
    }
    allPromocionesPromosSubBlock(filter: { tag: { eq: $group_name }, lang: { eq: $locale } }) {
      edges {
        node {
          id
          title
          description
          alt
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          imgsize
          stars
          buttons {
            cta
            size
            color
            ctaText
          }
          description
        }
      }
    }
    allPromosFullaBlock(filter: { tag: { eq: $group_name }, lang: { eq: $locale } }) {
      edges {
        node {
          tag
          title
          body
        }
      }
    }
    bannerDesktop: allBannerImageBlockPromo(
      filter: {
        tag: { eq: $group_name }
        lang: { eq: $locale }
        _name: { eq: "Banner pase anual" }
      }
    ) {
      edges {
        node {
          alt
          image
          localImage {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    bannerMobile: allBannerImageBlockPromo(
      filter: {
        tag: { eq: $group_name }
        lang: { eq: $locale }
        _name: { eq: "Banner pase anual mobile" }
      }
    ) {
      edges {
        node {
          alt
          image
          localImage {
            childImageSharp {
              fluid(maxWidth: 450, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allFichaPromosBreadCrumbBlock(filter: { tag: { eq: $group_name }, lang: { eq: $locale } }) {
      edges {
        node {
          destination_url
          name
        }
      }
    }
    allFichaPromosRichData(filter: { tag: { eq: $group_name }, lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            color_css_class
            og_description
            og_title
            twitter_description
            twitter_title
            created_at
            updated_at
            rich_markup_type
            imgRich
            price
            date_valid_until
            date_valid_start
          }
        }
      }
    }
  }
`;
